


















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import SingleImageUpload, { UploadImageData } from '@/components/SingleImageUpload.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import TextInput from '@/components/partials/TextInput.vue';
import TextSelect from '@/components/partials/TextSelect.vue';
import TextInputArea from '@/components/partials/TextInputArea.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import Header from '@/assets/headers';
import Role from '@/assets/roles';
import Services from '@/assets/services/Services';
import { InnovamoUser, UserRole, UserState } from '@/apis/userapi';
import BackLinkWithArrow from '@/components/partials/BackLinkWithArrow.vue';
import ButtonType from '@/assets/buttonTypes';
import Page from '@/components/partials/Page.vue';

@Component({
  components: {
    SingleImageUpload,
    TextHeader,
    TextInput,
    TextSelect,
    TextInputArea,
    RoundedButtonFilled,
    LoadingAnimation,
    BackLinkWithArrow,
    Page,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class UserEditing extends Vue {
    @Prop({ default: undefined })
    private userId!: string;

    @Prop({ default: undefined })
    private textHeaderProp!: string;

    private Header = Header;
    private ButtonType = ButtonType;
    private Role = Role;
    private UserState = UserState;
    private personalData: InnovamoUser = {
      companyLogo: {},
    };
    private salutationOptions: string[] = ['Herr', 'Frau', 'Divers'];
    private salutation: string = 'Herr';
    private stateOptions: string[] = ['In Prüfung', 'Geblockt', 'Freigeschaltet'];
    private userState: string = 'In Prüfung';
    private roleOptions: string[] = ['Bürger:in', 'Entwickler:in', 'Admin'];
    private role: string = 'Bürger:in';
    private logoImageFile: File | null = null;
    private initialLoading: boolean = true;
    private showUnexpectedError : boolean = false;
    private updateOrCreateUserLoading: boolean = false;
    private errors: string[] = [];
    private success: boolean = false;
    private textHeader: string = 'Neuen Benutzer anlegen';
    private addetingExistingUser = !!this.userId;

    private async mounted() {
      if (this.addetingExistingUser) {
        this.textHeader = this.textHeaderProp.replaceAll('-', '');
        try {
          const personalDataReponse = await Services.users.getUserByUserId(this.userId);
          this.personalData = personalDataReponse.data;
        } catch (error) {
          this.showUnexpectedError = true;
        } finally {
          this.initialLoading = false;
        }
      }
      this.initialLoading = false;
    }

    private mapRoleStringToViewString(role: string): string {
      switch (role) {
        case 'User':
          return 'Bürger:in';
        case 'Developer':
          return 'Entwickler:in';
        default:
          return 'Admin';
      }
    }

    private mapRoleStringToUserRole(role: string): UserRole {
      switch (role) {
        case 'Bürger:in':
          return { role: Role.USER };
        case 'Entwickler:in':
          return { role: Role.DEVELOPER };
        default:
          return { role: Role.ADMIN };
      }
    }

    private mapUserStateStringToUserState(userState: string): UserState | undefined {
      switch (userState) {
        case 'In Prüfung':
          return UserState.Examination;
        case 'Geblockt':
          return UserState.Blocked;
        default:
          return UserState.Unlocked;
      }
    }

    private async updateUser() {
      this.updateOrCreateUserLoading = true;
      this.errors = [];
      this.success = false;
      if (this.personalData.password === '') {
        this.personalData.password = undefined;
      }
      if (this.personalData.passwordAgain === '') {
        this.personalData.passwordAgain = undefined;
      }
      try {
        await Services.users.updateUserByUserId(this.userId, this.personalData);
        if (this.personalData.role) {
          this.textHeader = `${this.mapRoleStringToViewString((this.personalData.role.role as string))}  ${`${this.personalData.firstname as string} ${this.personalData.lastname as string}`} bearbeiten`;
        }
        this.success = true;
        this.personalData.password = undefined;
        this.personalData.passwordAgain = undefined;
        setTimeout(() => {
          this.success = false;
        }, 5000);
      } catch (err: unknown) {
        const error = err as AxiosError;
        this.updateOrCreateUserLoading = false;
        if (error.response) {
          this.errors = error.response.data.errorCodes;
        }
      }
      this.updateOrCreateUserLoading = false;
    }

    private async updateUserState(userState: UserState) {
      this.updateOrCreateUserLoading = true;
      this.success = false;
      try {
        await Services.users.updateUserStateByUserId(this.userId, userState);
        const personalDataReponse = await Services.users.getUserByUserId(this.userId);
        this.personalData = personalDataReponse.data;
        this.success = true;
        setTimeout(() => {
          this.success = false;
        }, 5000);
      } catch (error) {
        this.updateOrCreateUserLoading = false;
        console.log(error);
      }
      this.updateOrCreateUserLoading = false;
    }

    private async createUser() {
      this.updateOrCreateUserLoading = true;
      this.errors = [];
      this.success = false;
      if (this.personalData.password === '') {
        this.personalData.password = undefined;
      }
      if (this.personalData.passwordAgain === '') {
        this.personalData.passwordAgain = undefined;
      }
      try {
        this.personalData = {
          emailAddress: this.personalData.emailAddress,
          salutation: this.salutation,
          role: this.mapRoleStringToUserRole(this.role),
          firstname: this.personalData.firstname,
          lastname: this.personalData.lastname,
          birthdayDate: this.personalData.birthdayDate,
          countryRegion: this.personalData.countryRegion,
          password: this.personalData.password,
          passwordAgain: this.personalData.passwordAgain,
          userState: this.mapUserStateStringToUserState(this.userState),
          companyName: this.personalData.companyName,
          companyDescription: this.personalData.companyDescription,
          companyLogo: this.personalData.companyLogo,
        };
        await Services.users.createUser(this.personalData);
        this.success = true;
        this.personalData.password = undefined;
        this.personalData.passwordAgain = undefined;
        setTimeout(() => {
          this.success = false;
          this.$router.push('/user-management');
        }, 2000);
      } catch (err: unknown) {
        const error = err as AxiosError;
        this.updateOrCreateUserLoading = false;
        if (error.response) {
          this.errors = error.response.data.errorCodes;
        }
      }
      this.updateOrCreateUserLoading = false;
    }

    private get logoImagePath(): UploadImageData {

      const imageData: UploadImageData = {
        path: '',
        filename: '',
      };

      if (this.personalData.companyLogo && this.personalData.companyLogo.id) {

        imageData.filename = `${this.personalData.companyLogo.filename}.${this.personalData.companyLogo.filetype}`;

        if (this.personalData.companyLogo.isTemporaryUpload) {
          imageData.path = `${process.env.VUE_APP_USER_SERVICE_IMAGE_URL}temporary/${this.personalData.companyLogo.id}`;
        } else {
          imageData.path = `${process.env.VUE_APP_USER_SERVICE_IMAGE_URL}${this.personalData.companyLogo.id}`;
        }
      }
      return imageData;
    }

    private async uploadCompanyLogo(imageFile: File) {
      if (this.personalData.companyLogo && this.personalData.companyLogo.id !== undefined) {
        await this.removeSelectedLogoImage();
      }
      const response = await Services.users.uploadImageTemporary(imageFile);
      this.personalData.companyLogo = response.data;
    }

    private async deleteCompanyLogo() {
      await this.removeSelectedLogoImage();
    }

    private async removeSelectedLogoImage() {
      this.logoImageFile = null;

      if (this.personalData.companyLogo && this.personalData.companyLogo.isTemporaryUpload) {
        await Services.users.deleteTemporaryImage(this.personalData.companyLogo.id as number);
      } else if (this.personalData.companyLogo) {
        await Services.users.deleteImage(this.personalData.companyLogo.id as number);
      }

      this.personalData.companyLogo = {};
    }
}
